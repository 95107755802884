import React, { FC, useMemo } from 'react';
import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    useBreakpointValue
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import DataConsentCheckbox from 'ts/common/components/DataConsentCheckbox';
import Button from 'ts/common/components/gallery/Button';
import FormErrorMessage from 'ts/common/components/gallery/FormErrorMessage';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import { modalButtonWidth } from 'ts/common/constants/theme/common';
import { VALID_EMAIL_REGEX } from 'ts/common/hooks/useClientCredentials';
import StudioLogo from 'client_react/gallery/components/StudioLogo';
import InfoTooltip from './InfoTooltip';

interface IEventDataSettings {
    preRegistrationMessage: string;
    requireEmail: boolean;
    requirePassword: boolean;
}

interface IAccessFormProps {
    /** The name of the studio's brand */
    brandName: string;
    /** The logo of the studio's brand */
    eventBrandLogo?: Nullable<SpApi.IBrandLogoImage>;
    /** Alt text for the logo of the studio's brand */
    logoAltText: string;
    /** Is the gallery in pre-release mode */
    isPreRegister: boolean;
    /** Is the user on the admin route */
    isAdminRoute: boolean;
    /** Did the user successfully register */
    isRegistrationComplete: boolean;
    /** Message from successful registration */
    successMessage: string;
    /** Gallery settings */
    eventDataSettings: IEventDataSettings;
    /** The title (or name) of the gallery */
    galleryTitle: string;
    /** Does the user need to consent to their data being collected */
    isSubjectToGdpr: boolean;
    /** Response errors */
    submitErrors?: Record<string, Record<string, string>>;
    /** Callback called when the user registers */
    onSubmit: (data: {
        email?: string;
        password?: string;
        doesAcceptTerms?: boolean;
    }) => Promise<void>;
    /** Translation filter object from AngularJS */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const AccessForm: FC<IAccessFormProps> = ({
    brandName,
    eventBrandLogo,
    logoAltText,
    isPreRegister,
    isAdminRoute,
    isRegistrationComplete,
    successMessage,
    eventDataSettings: { preRegistrationMessage, requireEmail, requirePassword },
    galleryTitle,
    isSubjectToGdpr,
    submitErrors,
    onSubmit,
    translateFilter: t
}) => {
    const {
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        register
    } = useForm({ mode: 'onTouched' });

    const { header, subheader, submitLabel, submitLabelLarge, showForm, showEmail, showPassword } =
        useMemo(() => {
            if (isPreRegister) {
                if (isAdminRoute) {
                    return {
                        header: galleryTitle,
                        subheader: t('clientGalleryEmailOrAdminGreet'),
                        submitLabel: t('enter'),
                        submitLabelLarge: t('enterAdminMode'),
                        showForm: true,
                        showEmail: true,
                        showPassword: false
                    };
                }

                if (isRegistrationComplete) {
                    return {
                        header: t('thankYou'),
                        subheader: successMessage,
                        showForm: false,
                        showEmail: false,
                        showPassword: false
                    };
                }

                return {
                    header: galleryTitle,
                    subheader: preRegistrationMessage,
                    submitLabel: t('notifyMe'),
                    submitLabelLarge: t('notifyMe'),
                    showForm: true,
                    showEmail: true,
                    showPassword: false
                };
            }

            return {
                header: galleryTitle,
                subheader: requireEmail
                    ? requirePassword
                        ? t('clientGalleryEmailAndPasswordGreet')
                        : t('clientGalleryEmailOrAdminGreet')
                    : t('clientGalleryPasswordGreet'),
                submitLabel: t('viewGallery'),
                submitLabelLarge: t('viewGallery'),
                showForm: true,
                showEmail: requireEmail,
                showPassword: requirePassword
            };
        }, [
            isPreRegister,
            t,
            requireEmail,
            requirePassword,
            isAdminRoute,
            isRegistrationComplete,
            preRegistrationMessage,
            successMessage,
            galleryTitle
        ]);

    const isButtonFullWidth = useBreakpointValue({ base: true, lg: false });

    return (
        <Flex alignItems="center" flexDirection="column" height="full">
            <Box paddingBottom="32px" textAlign="center">
                {eventBrandLogo ? (
                    <StudioLogo logo={eventBrandLogo} logoAltText={logoAltText} logoSize="md" />
                ) : (
                    <StudioHeading text={brandName} variant="primary" />
                )}
            </Box>
            <Box paddingBottom="32px" textAlign="center">
                <GalleryHeading size="sm" text={header} variant="primary" />
            </Box>
            <Text fontSize="md" paddingBottom="32px">
                {subheader}
            </Text>
            {showForm && (
                <Flex
                    as="form"
                    aria-label={header}
                    onSubmit={handleSubmit(onSubmit)}
                    alignSelf="stretch"
                    flexDirection="column"
                    flex={1}
                    height="full"
                    justifyContent="space-between"
                    width="full"
                >
                    <Flex flexDirection="column">
                        {showEmail && (
                            <FormControl isInvalid={Boolean(errors.email)} isRequired>
                                <FormLabel>{t('emailAddress')}</FormLabel>
                                <Input
                                    {...register('email', {
                                        required: t('emailAddressInvalid'),
                                        pattern: {
                                            value: VALID_EMAIL_REGEX,
                                            message: t('emailAddressInvalid')
                                        }
                                    })}
                                />
                                {errors.email && (
                                    <FormErrorMessage>
                                        {errors.email.message as string}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                        )}
                        {submitErrors?.email && (
                            <Box paddingBottom={6}>
                                {Object.values(submitErrors.email).map((error) => (
                                    <Text key={error} variant="error">
                                        {error}
                                    </Text>
                                ))}
                            </Box>
                        )}
                        {showPassword && (
                            <FormControl isInvalid={Boolean(errors.password)} isRequired>
                                <Flex alignItems="center" flexDirection="row">
                                    <FormLabel>{t('clientGalleryPassword')}</FormLabel>
                                    <InfoTooltip
                                        infoIconLabel={t('clientGalleryPasswordTooltipIcon')}
                                        tooltipLabel={t('clientGalleryPasswordTooltip')}
                                    />
                                </Flex>
                                <Input
                                    type="password"
                                    {...register('password', {
                                        required: t('passwordMissingGallery')
                                    })}
                                />
                                {errors.password && (
                                    <FormErrorMessage>
                                        {errors.password.message as string}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                        )}
                        {submitErrors?.password && (
                            <Box paddingBottom={6}>
                                {Object.values(submitErrors.password).map((error) => (
                                    <Text key={error} variant="error">
                                        {error}
                                    </Text>
                                ))}
                            </Box>
                        )}
                        {isSubjectToGdpr && (
                            <DataConsentCheckbox register={register} translate={t} />
                        )}
                    </Flex>
                    {submitLabel && (
                        <Button
                            type="submit"
                            alignSelf="center"
                            isDisabled={!isValid}
                            fullWidth={isButtonFullWidth}
                            isLoading={isSubmitting}
                            minWidth={modalButtonWidth}
                            text={submitLabel}
                            textLarge={submitLabelLarge}
                            variant="primary"
                        />
                    )}
                </Flex>
            )}
        </Flex>
    );
};

export default AccessForm;
