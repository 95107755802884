import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { Mail } from 'react-feather';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useGalleryApiFetch } from 'ts/client/common';
import DataConsentCheckbox from 'ts/common/components/DataConsentCheckbox';
import { Button, ModalHeader } from 'ts/common/components/gallery';
import { FetchMethod, useTranslate } from 'ts/common/hooks';
import { VALID_EMAIL_REGEX } from 'ts/common/hooks/useClientCredentials';
import { ISetEmailResponse } from './types';

export interface IFormValues {
    email: string;
    confirmEmail: string;
    doesAcceptTerms: boolean;
}

interface IEmailCaptureFormProps {
    buttonText: string;
    headerText: string;
    headerSubText: string;
    isLoading: boolean;
    isSubjectToGdpr: boolean;
    onClose?: () => void;
    primaryAction?: () => void;
    setEmailSetSuccess: (success: boolean) => void;
}

const EmailCaptureForm: React.FC<IEmailCaptureFormProps> = ({
    buttonText,
    headerText,
    headerSubText,
    isLoading,
    isSubjectToGdpr,
    onClose,
    primaryAction,
    setEmailSetSuccess
}) => {
    const {
        formState: { errors, isSubmitting, isValid },
        getFieldState,
        handleSubmit,
        register,
        setError,
        watch
    } = useForm<IFormValues>({
        defaultValues: {
            email: '',
            confirmEmail: ''
        },
        mode: 'onBlur'
    });

    const t = useTranslate();
    const { email, confirmEmail } = watch();
    const emailNotMatch = email !== confirmEmail;
    const errorEmailNotMatch = email !== confirmEmail && getFieldState('confirmEmail').isTouched;

    const {
        response: setEmailResponse,
        performFetch: setEmailRequest,
        loading: setEmailRequestLoading
    } = useGalleryApiFetch<ISetEmailResponse>('setemail', {
        defer: true,
        method: FetchMethod.POST
    });

    const onSubmit: SubmitHandler<IFormValues> = ({ doesAcceptTerms, email }) => {
        if (!emailNotMatch) {
            setEmailRequest({
                body: new URLSearchParams({
                    email: email,
                    ...(isSubjectToGdpr ? { doesAcceptTerms: doesAcceptTerms ? '1' : '0' } : {})
                })
            });
        }
    };

    useEffect(() => {
        if (setEmailResponse && setEmailResponse.statusText === 'success') {
            if (primaryAction) {
                primaryAction();
            } else {
                setEmailSetSuccess(true);
            }
        }

        if (setEmailResponse?.errors?.doesAcceptTerms?.isEmpty) {
            setError('doesAcceptTerms', {
                type: 'server',
                message: setEmailResponse.errors.doesAcceptTerms.isEmpty
            });
        }
    }, [setEmailResponse, setError, setEmailSetSuccess, primaryAction]);

    return (
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader modalIcon={Mail} headerText={headerText} headerSubText={headerSubText} />
            <FormControl
                paddingTop="32px"
                isInvalid={errors?.email?.type === 'required' || errors?.email?.type === 'pattern'}
            >
                <FormLabel
                    color="text"
                    data-testid="email-input-label"
                    fontFamily="open-sans, sans-serif"
                    fontSize="md"
                    htmlFor="email-input"
                    marginBottom="2px"
                >
                    {t('client.gallery.emailCaptureForm.emailAddress')}
                </FormLabel>
                <Input
                    id="email-input"
                    {...register('email', {
                        required: true,
                        pattern: VALID_EMAIL_REGEX
                    })}
                    size="sm"
                />
                {errors?.email?.type === 'required' && (
                    <FormErrorMessage>
                        {t('client.gallery.emailCaptureForm.emailRequired')}
                    </FormErrorMessage>
                )}
                {errors?.email?.type === 'pattern' && (
                    <FormErrorMessage>
                        {t('client.gallery.emailCaptureForm.enterValidEmail')}
                    </FormErrorMessage>
                )}
            </FormControl>
            <FormControl
                isInvalid={
                    errors?.confirmEmail?.type === 'required' ||
                    errors?.confirmEmail?.type === 'pattern' ||
                    errorEmailNotMatch
                }
            >
                <FormLabel
                    color="text"
                    data-testid="confirm-email-input-label"
                    fontFamily="open-sans, sans-serif"
                    fontSize="md"
                    htmlFor="confirm-email-input"
                    marginBottom="2px"
                >
                    {t('client.gallery.emailCaptureForm.confirmEmail')}
                </FormLabel>
                <Input
                    id="confirm-email-input"
                    {...register('confirmEmail', {
                        required: true,
                        pattern: VALID_EMAIL_REGEX
                    })}
                    size="sm"
                />
                {errors?.confirmEmail?.type === 'required' && (
                    <FormErrorMessage>
                        {t('client.gallery.emailCaptureForm.confirmEmailRequired')}
                    </FormErrorMessage>
                )}
                {errors?.confirmEmail?.type === 'pattern' && (
                    <FormErrorMessage>
                        {t('client.gallery.emailCaptureForm.enterValidEmail')}
                    </FormErrorMessage>
                )}
                {errorEmailNotMatch && (
                    <FormErrorMessage>
                        {t('client.gallery.emailCaptureForm.emailAddressesMustMatch')}
                    </FormErrorMessage>
                )}
            </FormControl>
            {isSubjectToGdpr && <DataConsentCheckbox register={register} translate={t} />}
            <Flex marginTop="12px" gap={onClose ? '20px' : '0'}>
                {onClose && (
                    <Button
                        data-testid="email-capture-cancel-button"
                        fullWidth
                        onClick={onClose}
                        variant="outline primary"
                        text={t('client.gallery.emailCaptureForm.cancel')}
                    />
                )}
                <Button
                    type="submit"
                    isDisabled={!isValid || errorEmailNotMatch}
                    fullWidth
                    isLoading={setEmailRequestLoading || isSubmitting || isLoading}
                    text={buttonText}
                    variant="primary"
                    data-testid="email-capture-button"
                />
            </Flex>
        </Box>
    );
};

export default EmailCaptureForm;
