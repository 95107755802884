import React, { FC, useCallback } from 'react';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { Heart, ShoppingCart } from 'react-feather';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import DataConsentCheckbox from 'ts/common/components/DataConsentCheckbox';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';
import { VALID_EMAIL_REGEX } from 'ts/common/hooks/useClientCredentials';
import ModalButtonFooter from './ModalButtonFooter';

interface ICollectEmailCredentialsModalProps {
    isCollectFavoritesEmail?: boolean;
    isRecoverCart?: boolean;
    isRecoverFavorites?: boolean;
    isSubjectToGdpr: boolean;
    onClose: () => void;
    onSubmit: (email: string, doesAcceptTerms: boolean) => void;
    $rootScope: {
        saveEmail: (email: string) => void;
    };
    translateFilter: SpAngularJs.ITranslateFilter;
}

const CollectEmailCredentialsModal: FC<ICollectEmailCredentialsModalProps> = ({
    isCollectFavoritesEmail = false,
    isRecoverCart = false,
    isRecoverFavorites = false,
    isSubjectToGdpr = false,
    onClose,
    onSubmit,
    $rootScope: { saveEmail },
    translateFilter: t
}) => {
    const {
        formState: { errors, isValid },
        handleSubmit,
        register
    } = useForm({ mode: 'onTouched' });

    const handleFormSubmitted = useCallback(
        ({ email, doesAcceptTerms }) => {
            saveEmail(email);
            onSubmit(email, doesAcceptTerms);
        },
        [onSubmit, saveEmail]
    );

    return (
        <Box>
            {isCollectFavoritesEmail && (
                <ModalHeader
                    modalIcon={Heart}
                    headerText={t('favoritePhoto')}
                    headerSubText={t('favoriteAPhotoHelper')}
                />
            )}
            {isRecoverCart && (
                <ModalHeader
                    modalIcon={ShoppingCart}
                    headerText={t('recoverCart')}
                    headerSubText={t('recoverCartDescription')}
                />
            )}
            {isRecoverFavorites && (
                <ModalHeader
                    modalIcon={Heart}
                    headerText={t('recoverFavorites')}
                    headerSubText={t('recoverFavoritesDescription')}
                />
            )}
            <Flex
                as="form"
                flexDirection="column"
                paddingTop="32px"
                onSubmit={handleSubmit(handleFormSubmitted as SubmitHandler<FieldValues>)}
            >
                <Box paddingBottom="128px">
                    <FormControl isInvalid={Boolean(errors.email)} isRequired>
                        <FormLabel>{t('emailAddress')}</FormLabel>
                        <Input
                            {...register('email', {
                                required: t('emailAddressInvalid'),
                                pattern: {
                                    value: VALID_EMAIL_REGEX,
                                    message: t('emailAddressInvalid')
                                }
                            })}
                        />
                        {errors.email && (
                            <FormErrorMessage>{errors.email.message as string}</FormErrorMessage>
                        )}
                    </FormControl>
                    {isSubjectToGdpr && <DataConsentCheckbox register={register} translate={t} />}
                </Box>
                <ModalButtonFooter
                    isPrimaryDisabled={!isValid}
                    secondaryButtonAction={onClose}
                    primaryButtonText={t('submit')}
                    primaryLoadingText={t('submitting')}
                    secondaryButtonText={t('cancel')}
                />
            </Flex>
        </Box>
    );
};

export default CollectEmailCredentialsModal;
